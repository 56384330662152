import type { QueryKey } from "@tanstack/react-query"

import {
  CAREER_CENTER_AUTOCOMPLETE_QUERY_KEY,
  CAREER_CENTER_INFOS_QUERY_KEY,
  CAREER_CENTER_QUERY_KEY,
  SCHOOL_AUTOCOMPLETE_QUERY_KEY,
} from "@/modules/careerCenter/services/constants"

type GetCareerCenterQueryKeyParams = {
  permalink: string
}

type GetCareerCenterAutocompleteQueryKeyParams = {
  term: string
}

type GetCareerCenterInfosQueryKeyParams = {
  schoolName: string
}

type GetSchoolAutocompleteQueryKeyParams = {
  term: string
}

export function getCareerCenterAutocompleteQueryKey({ term }: GetCareerCenterAutocompleteQueryKeyParams): QueryKey {
  return [CAREER_CENTER_AUTOCOMPLETE_QUERY_KEY, term]
}
export function getCareerCenterInfosQueryKey({ schoolName }: GetCareerCenterInfosQueryKeyParams): QueryKey {
  return [CAREER_CENTER_INFOS_QUERY_KEY, schoolName]
}
export function getCareerCenterQueryKey({ permalink }: GetCareerCenterQueryKeyParams): QueryKey {
  return [CAREER_CENTER_QUERY_KEY, permalink]
}
export function getSchoolAutocompleteQueryKey({ term }: GetSchoolAutocompleteQueryKeyParams): QueryKey {
  return [SCHOOL_AUTOCOMPLETE_QUERY_KEY, term]
}
