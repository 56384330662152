import type { LayoutProps } from "@/modules/corporate/components/Layout/types"
import type { Page } from "@/modules/corporate/types/pages"
import type { I18nLocale } from "@/modules/i18n/types"
import type { FunctionComponent } from "react"

import cn from "classnames"
import Image from "next/image"
import pDebounce from "p-debounce"
import { useEffect, useState } from "react"

import IconBurgerMenu from "@jobteaser/ui-kit/assets/icons/jds_burgerMenu.svg"
import IconCross from "@jobteaser/ui-kit/assets/icons/jds_cross.svg"
import { Icon, ICON_SIZES } from "@jobteaser/ui-kit/components/Icon/Icon"

import JtLogoColorTeasyDarkBackground from "@/modules/brand/assets/jt-logo-color-teasy-dark-background.svg"
import { Card } from "@/modules/corporate/components/Card/Card"
import { MainNavigation } from "@/modules/corporate/components/Header/MainNavigation"
import { TopNavigation } from "@/modules/corporate/components/Header/TopNavigation"
import { InternalLink } from "@/modules/corporate/components/InternalLink"
import { Locales } from "@/modules/corporate/components/Locales/Locales"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"

import { LoginLink } from "./LoginLink"

import styles from "./Header.module.css"
import logoStyles from "@/modules/headerFooterFO/Brand/Brand.module.css"

type Props = {
  currentPersonaRootPage: Page | null | undefined
  isMobileMenuOpened: boolean
  page: Page
  pageNavigation: Page[]
  pageTranslations: Partial<Record<I18nLocale, string>>
  personas: LayoutProps["personas"]
  onNavigationLinkClick: () => void
  toggleMenu: () => void
}

const SCROLL_POSITION = 40

export const Header: FunctionComponent<Props> = ({
  currentPersonaRootPage,
  isMobileMenuOpened,
  page,
  pageNavigation,
  pageTranslations,
  personas,
  onNavigationLinkClick,
  toggleMenu,
}) => {
  const locale = useLocale()
  const { t } = useTranslation(["module_header_fo"])
  const trackClickEvent = useCorporateTrackingClick()

  const [shouldApplyClass, setShouldApplyClass] = useState(false)
  const [isFixedRemove, setIsFixedRemove] = useState(false)
  const [prevScrollPosition, setPrevScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = pDebounce(() => {
      const currentScrollPosition = window.scrollY
      const hasScrolledPastThreshold = currentScrollPosition > SCROLL_POSITION
      const isScrollingUp = currentScrollPosition < prevScrollPosition

      if (isScrollingUp) {
        if (!shouldApplyClass && hasScrolledPastThreshold) {
          setShouldApplyClass(true)
          setIsFixedRemove(false)
        } else if (shouldApplyClass && currentScrollPosition <= SCROLL_POSITION) {
          setShouldApplyClass(false)
          setIsFixedRemove(false)
        }
      } else if (shouldApplyClass && hasScrolledPastThreshold) {
        setIsFixedRemove(true)
      }

      if (isScrollingUp && isFixedRemove) {
        setIsFixedRemove(false)
      }

      setPrevScrollPosition(currentScrollPosition)
    }, 50)

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [prevScrollPosition, shouldApplyClass, isFixedRemove])

  return (
    <header
      className={cn(
        styles.wrapper,
        { [styles["wrapper--fixed"]]: shouldApplyClass },
        { [styles["wrapper--fixedRemove"]]: isFixedRemove }
      )}
      role="banner"
    >
      <div className={cn(styles.main, { [styles["main--open"]]: isMobileMenuOpened })}>
        <div className={styles.brand}>
          {currentPersonaRootPage ? (
            <InternalLink
              onClick={() =>
                trackClickEvent("corporate_nav_logo_jt", {
                  clicked_url: `/${locale}/${URL_PATHNAME}/${currentPersonaRootPage.slug}`,
                })
              }
              page={currentPersonaRootPage}
            >
              <Image
                className={cn(logoStyles["app-JtLogo"], logoStyles["app-JtLogo___header"])}
                src={JtLogoColorTeasyDarkBackground}
                alt=""
                height={24}
                width={128}
                priority
              />
            </InternalLink>
          ) : (
            // eslint-disable-next-line @next/next/no-html-link-for-pages
            <a
              href={`/${locale}`}
              onClick={() => trackClickEvent("corporate_nav_logo_jt", { clicked_url: `/${locale}` })}
            >
              <Image
                className={styles.siteLogo}
                src={JtLogoColorTeasyDarkBackground}
                alt=""
                height={28}
                width={151}
                priority
              />
            </a>
          )}
          <button
            type="button"
            aria-label={`${t("module_header_fo.icon_menu")}`}
            className={styles.menuButton}
            onClick={() => {
              toggleMenu()
              trackClickEvent("corporate_menu_toggle", {
                visual_context: isMobileMenuOpened ? "close" : "open",
              })
            }}
          >
            <Icon
              size={ICON_SIZES.BIG}
              xlinkHref={!isMobileMenuOpened ? `#${IconBurgerMenu.id}` : `#${IconCross.id}`}
            />
          </button>
        </div>
        <nav className={styles.topNav}>
          <TopNavigation
            currentPersona={page.persona.length ? page.persona[0] : undefined}
            personas={personas}
            onNavigationLinkClick={() => {
              onNavigationLinkClick()
            }}
          />
        </nav>
        <nav className={styles.mainNav} role="navigation" aria-label="Main">
          <MainNavigation
            pageNavigation={pageNavigation}
            onNavigationLinkClick={() => {
              onNavigationLinkClick()
            }}
          />
        </nav>
        <div className={styles.language}>
          <Locales isInMenu pageTranslations={pageTranslations} />
        </div>

        <LoginLink className={styles.login} label={t("module_header_fo.login_button.label")} />

        {currentPersonaRootPage && !!currentPersonaRootPage.contactPage?.length && (
          <div className={styles.contact}>
            <Card
              ctaLink={currentPersonaRootPage.contactPage[0]}
              ctaText={currentPersonaRootPage.contactPage[0].menuTitle || currentPersonaRootPage.contactPage[0].title}
              onCTAClick={() =>
                trackClickEvent("corporate_contact_button", {
                  visual_context: currentPersonaRootPage.persona.length ? currentPersonaRootPage.persona[0] : undefined,
                })
              }
              description={currentPersonaRootPage.contactPage[0].shortDescription}
              isInMenu
              title={currentPersonaRootPage.contactPage[0].subtitle}
              theme="purple"
            />
          </div>
        )}
      </div>
    </header>
  )
}
